import JwtDecoder from "jwt-decode";
import { ApiWebSocket } from "./websocket";

export function retrieveToken() {
  const queryParams = new URLSearchParams(window.location.search);
  let token = queryParams.get("t");
  let patientId = queryParams.get("patientId");

  if (token) {
    window.localStorage.setItem("t", token);
    window.localStorage.setItem("patientId", patientId);
    window.history.replaceState(
      {},
      document.title,
      window.location.href.replace(window.location.search, "")
    );
  } else {
    token = window.localStorage.getItem("t");
    patientId = window.localStorage.getItem("patientId");
    if (!token) {
      throw Error("accessNotAllowed");
    }
  }

  const objectInfo = JwtDecoder(token);

  if (
    !objectInfo.token ||
    !objectInfo.room ||
    !objectInfo.username ||
    !objectInfo.sessionId
  ) {
    throw Error("accessNotAllowed");
  }

  const slug = queryParams.get("slug");

  if (objectInfo.apiUrl && slug) {
    ApiWebSocket.connect(objectInfo.apiUrl, slug, objectInfo.type);
  }

  return {
    apikey: objectInfo.apiKey,
    sessionId: objectInfo.sessionId,
    token: objectInfo.token,
    username: objectInfo.username,
    room: objectInfo.room,
    patientId,
  };
}
